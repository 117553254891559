import TopBanner from './components/topBanner/topBanner'
import ChampionshipList from './components/championshipList/championshipList'
import 'react-calendar-datetime-picker/dist/style.css'

const Championship = ({date, searchData}) => {
  return (
    <div style={{margin: 'auto', maxWidth: '1920px', paddingTop: '-120px'}}>
      {/* <TopBanner date={date}></TopBanner> */}
      <ChampionshipList date={date} searchData={searchData}></ChampionshipList>
    </div>
  )
}
export default Championship
