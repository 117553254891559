import axios, {AxiosResponse} from 'axios'
import {StreamingResponse} from '../models/response/StreamingPayload'
import {StreamingPayload} from '../models/payload/StreamingPayload'
import streamingClient from '../streamingClient'
import { ID } from '../../../_metronic/helpers'


export function postFiles(payload: any) {
  return streamingClient.post<StreamingPayload>(`/Files`, payload)
}

export function getFilesById(key: string) {
  let path = `/Files/${key}`
  return streamingClient.get<StreamingResponse>(path)
}

export function updateFiles(key: string, payload: any) {
  return streamingClient.put<StreamingResponse>(
    `/Files/${key}`,
    payload
  )
}

export function deleteFileTag(key: string, tagId: number): Promise<AxiosResponse<any>> {
  return streamingClient.delete(`/Files/${key}/Tags/${tagId}`)
}

export function postFileTags(key: string, payload: any) {
  return streamingClient.post<StreamingPayload>(
    `/Files/${key}/Tags`,
    payload
  )
}

export const deleteFile = (key: any): Promise<void> => {
  return streamingClient.delete(`/Files/${key}`).then(() => {})
}

export const deleteSelectedFile = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`/${id}`))
  return axios.all(requests).then(() => {})
}

export function getStreamingFiles(query: string) {
  return streamingClient.get(`/Files?${query}`, {
    headers: {accept: 'application/json'},
  })
  // .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}