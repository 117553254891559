import axios from 'axios'
import {SystemParametersPayload} from '../models/payload/SystemParametersPayload'
import {SystemParametersResponse} from '../models/response/SystemParameters'
import { ID } from '../../../_metronic/helpers'
import { GameGenericType } from '../models/response/GamePayload'
import streamingClient from '../streamingClient'
import coreClient from '../coreClient'

export function getTags(pageNumber?: number, pageSize?: number, platformId?: number) {
  let path = `/Tags`
  if (pageNumber != null || pageSize != null || platformId != null) {
    path += `?`
  }
  if (pageNumber != null) {
    path += `PageNumber=${pageNumber}&`
  }
  if (pageSize != null) {
    path += `PageSize=${pageSize}&`
  }
  if (platformId != null) {
    path += `&PlatformId=${platformId}&`
  }
  return streamingClient.get<GameGenericType[]>(path)
}

export function getTags2(query: string) {
  let path = `/Tags`
  return streamingClient.get(`${path}?${query}`, {
      headers: {accept: 'application/json'},
    })
}

export function postTags(payload: any) {
  return streamingClient.post<SystemParametersResponse>(
    `/Tags`,
    payload
  )
}

export function updateTags(id: number, payload: any) {
  return streamingClient.put<SystemParametersResponse>(
    `/Tags/${id}`,
    payload
  )
}

export function  getTagsById (id: number){
  return streamingClient.get<any>(`/Tags/${id}`, {
  })
  // .then((response: Response<any>) => {const result= response.data;console.log('check', result); return result})
}

export const deleteTag = (userId: ID): Promise<void> => {
  return streamingClient.delete(`/Tags/${userId}`).then(() => {})
}

//not used
export const deleteSelectedTags = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => streamingClient.delete(`/${id}`))
  return axios.all(requests).then(() => {})
}