import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'

import {usersColumns} from './columns/_columns'

import {KTCardBody} from '../../../../../../_metronic/helpers'
import {PaginatedItems} from '../../../../../shared/panination'
import api from '../../../../../api'
import { UsersPayload } from '../../../../../api/models/response/UsersPayload'
import { ENVIRONMENT, isCryptoEntity } from '../../../../../api/client'

type Props = {
  signupsData: UsersPayload[];
  totalSignUps: number
}

const SignUpTable = ({signupsData, totalSignUps}: Props) => {
  // const users = useQueryResponseData()
  // const pagination = useQueryPaginationData()
  // const isLoading = useQueryResponseLoading()
  // const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const [data, setSignUps] = useState<any>([])
  const channel = isCryptoEntity ? 2 : 1

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [totalCount, setTotalCount] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(0)
  const pageSize = ENVIRONMENT === 'prod' ? 10 : 2;

  useEffect(()=>{
    setSignUps(signupsData)
    setTotalCount(totalSignUps)
   }, [signupsData, totalSignUps])

  useEffect(()=>{
    if(pageNumber !==0 ){
      getSignUps(pageNumber, pageSize)
      }
  }, [pageNumber])

  const [formattedDate, setFormattedDate] = useState('');

  const getFormattedDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setFormattedDate(formattedDate);
  };

  const getSignUps = async (pageNum: number, size: number) => {
    try {
      console.log('date', formattedDate)
      const startDate = formattedDate + ' 00:00:00';
      const endDate = formattedDate + ' 23:59:59';
      const response = await api.users.getUsers(pageNum, size, channel, startDate, endDate)
      if (response.status == 200 || response.status == 201) {
        setSignUps(response.data['users'])
        const respHeaders = response.headers
        if(response.data['totalCount']){
          setTotalCount(response.data['totalCount'])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  //  const {updateState} = useQueryRequest()
  // const updatePage = (page: number | undefined | null) => {
  //   if (!page || isLoading || pagination.page === page) {
  //     return
  //   }
  // }

  const handlePageNumber = (num) => {
    const pageNumber = num
    setPageNumber(pageNumber)
    // updateState({PageNumber: num, PageSize: 10})
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <div className='border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Daily Signups</span>
          </h3>
        </div>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount && totalCount!==0  ? (
        <PaginatedItems
          itemsPerPage={pageSize}
          numberOfItems={totalCount}
          handleClick={handlePageNumber}
          pageNumber={pageNumber}
        />
      ) : null}
      {/* <UsersListPagination /> */}
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export {SignUpTable}
