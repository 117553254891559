/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTCard, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import TopBoxes, {SelectedDates} from './components/topBoxes'
import {ChampionshipTable} from './components/championshipTable/table/ChampionshipTable'
import {SignUpTable} from './components/signUpTable/table/SignUpTable'
import {ParticipationsTable} from './components/participationsTable/table/ParticipationsTable'
import {RewardedVideosTable} from './components/rewardedVideosTable/table/RewardedVideosTable'
import {ChampionshipPayload} from '../../api/models/response/ChampionshipPayload'
import {ParticipationsPayload} from '../../api/models/response/ParticipationsPayload'
import {UsersPayload} from '../../api/models/response/UsersPayload'
import {ENVIRONMENT, isCryptoEntity} from '../../api/client'
import api from '../../api'
import {getFormattedDate} from '../../utilitites/getCurrentDate'

const DashboardPage: FC = () => {
  // //winner
  // const [winner, setWinner] = useState<any>({})
  // const [winnerUser, setWinnerUser] = useState<any>({})

  // //Winner image
  // const [iconKey, setIconKey] = useState<FilesResponse>()
  // const [imgSrc, setImgSrc] = useState<string>()

  // useEffect(() => {
  //   getIcons(winner?.avatar2D)
  // }, [winner])

  // const getIcons = async (key: string) => {
  //   try {
  //     const resp = await api.files.get(key)
  //     if (resp.data.fileData) {
  //       setIconKey(resp.data)
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // useEffect(() => {
  //   setImgSrc(`data:image/${iconKey?.extension};base64, ${iconKey?.fileData}`)
  // }, [iconKey?.fileData])

  // useEffect(() => {
  //   getWinner()
  // }, [])
  // useEffect(() => {
  //   getUserById(winner?.userId)
  // }, [winner])

  // const getWinner = async () => {
  //   try {
  //     const response = await api.championship.get()
  //     if (response.status == 200) {
  //       setWinner(response.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // const getUserById = async (id: string) => {
  //   try {
  //     const response = await api.users.getById(id)
  //     if (response.status == 200) {
  //       setWinnerUser(response.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const [totalSignUpsBox, setTotalSignUpsBox] = useState<number>(1)
  const [totalSignUps, setTotalSignUps] = useState<number>(1)
  const [signupsData, setSignupsData] = useState<UsersPayload[]>([])

  const [totalWinnersBox, setTotalWinnersBox] = useState<number>(0)
  const [totalWinners, setTotalWinners] = useState<number>(0)
  const [winnersData, setWinnersData] = useState<ChampionshipPayload[]>([])

  const [totalRewardedVideos, setTotalRewardedVideos] = useState<number>(0)
  const [rewardedVideosCount, setRewardedVideosCount] = useState<number>(0)

  const [videosData, setVideosData] = useState<ParticipationsPayload[]>([])

  const [totalParticipations, setTotalParticipations] = useState<number>(0)
  const [participationsCount, setParticipationsCount] = useState<number>(0)
  const [participationsData, setParticipationsData] = useState<ParticipationsPayload[]>([])

  const defaultPageNum = 1
  const defaultPageSize = ENVIRONMENT === 'prod' ? 10 : 2
  const channel = isCryptoEntity ? 2 : 1
  const scoreMultiplier = 1

  useEffect(() => {
    getSignUps(defaultPageNum, defaultPageSize)
    getWinners(defaultPageNum, defaultPageSize)
    getParticipations(defaultPageNum, defaultPageSize)
    getRewardedVideos(scoreMultiplier, defaultPageNum, defaultPageSize)
  }, [])

  const getSignUps = async (
    pageNum: number,
    size: number,
    selectedStartDate?: any,
    selectedEndDate?: any
  ) => {
    try {
      let startDate = selectedStartDate
      let endDate = selectedEndDate
      if (selectedStartDate === undefined) {
        startDate = getFormattedDate().startDate
        endDate = getFormattedDate().endDate
      }

      const response = await api.users.getUsers(pageNum, size, channel, startDate, endDate)
      if (response.status == 200 || response.status == 201) {
        if (!selectedStartDate) {
          setSignupsData(response.data['users'])
          setTotalSignUps(response.data['totalCount'])
        } else {
          setTotalSignUpsBox(response.data['totalCount'])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getWinners = async (
    pageNum: number,
    size: number,
    selectedStartDate?: any,
    selectedEndDate?: any
  ) => {
    try {
      const response = await api.championship.getWinners(
        pageNum,
        size,
        selectedStartDate,
        selectedEndDate
      )
      if (response.status == 200 || response.status == 201) {
        const respHeaders = response.headers
        const xPagination = respHeaders['x-pagination']
        if (xPagination) {
          const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'))
          const totalCount = xPaginationObj.totalCount
          if (!selectedStartDate) {
            setWinnersData(response.data)
            setTotalWinners(totalCount)
          } else {
            setTotalWinnersBox(totalCount)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getRewardedVideos = async (
    scoreMultiplier: number,
    pageNum: number,
    size: number,
    selectedStartDate?: any,
    selectedEndDate?: any
  ) => {
    try {
      const response = await api.participations.getRewardedVideosPerUser(
        scoreMultiplier,
        pageNum,
        size,
        selectedStartDate,
        selectedEndDate
      )
      if (response.status == 200 || response.status == 201) {
        const respHeaders = response.headers
        const xPagination = respHeaders['x-pagination']
        if (xPagination) {
          const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'))
          if (!selectedStartDate) {
            setVideosData(response.data)
            setRewardedVideosCount(xPaginationObj.totalCount)
          }
          setTotalRewardedVideos(xPaginationObj.totalParticipations)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getParticipations = async (
    pageNum: number,
    size: number,
    selectedStartDate?: any,
    selectedEndDate?: any
  ) => {
    try {

      const response = await api.participations.getParticipants(
        pageNum,
        size,
        selectedStartDate,
        selectedEndDate
      )

      if (response.status == 200 || response.status == 201) {
        if (!selectedStartDate) {
          setParticipationsData(response.data)
        }
        const respHeaders = response.headers
        const xPagination = respHeaders['x-pagination']
        if (xPagination) {
          const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'))
          if (!selectedStartDate) {
            setParticipationsData(response.data)
            setParticipationsCount(xPaginationObj.totalCount)
          }
          setTotalParticipations(xPaginationObj.totalParticipations)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDateSignups = async (date: SelectedDates) => {
    getSignUps(defaultPageNum, defaultPageSize, date?.startDate, date?.endDate)
  }

  const handleDateRewardedVideos = async (date: SelectedDates) => {
    getRewardedVideos(
      scoreMultiplier,
      defaultPageNum,
      defaultPageSize,
      date?.startDate,
      date?.endDate
    )
  }

  const handleDateParticipations = async (date: SelectedDates) => {
    getParticipations(defaultPageNum, defaultPageSize, date?.startDate, date?.endDate)
  }

  const handleDateTotalWinners = async (date: SelectedDates) => {
    getWinners(defaultPageNum, defaultPageSize, date?.startDate, date?.endDate)
  }

  return (
    <>
      {/* <h1>Welcome to Mobile Esports Administrator platform!</h1> */}
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <TopBoxes
          value={totalWinnersBox}
          handledate={handleDateTotalWinners}
          text={'Daily Championship Winners'}
          color='#F7D9E3'
        />
        <TopBoxes
          value={totalSignUpsBox}
          handledate={handleDateSignups}
          text={'Daily Sign Ups'}
          color='#CBF0F4'
        />
        <TopBoxes
          value={totalRewardedVideos}
          handledate={handleDateRewardedVideos}
          text={'Daily Rewarded Videos'}
          color='#CBD4F4'
        />
        <TopBoxes
          value={totalParticipations}
          handledate={handleDateParticipations}
          text={'Daily Participations'}
          color='#fff2de'
        />
      </div>
      <div className='row'>
        <div className='col-lg-6 mb-8'>
          <KTCard>
            <ParticipationsTable
              participationsData={participationsData}
              participationsCount={participationsCount}
            />
          </KTCard>
        </div>
        <div className='col-lg-6'>
          <KTCard>
            <RewardedVideosTable
              videosData={videosData}
              rewardedVideosCount={rewardedVideosCount}
            />
          </KTCard>
        </div>
      </div>
      <div className='mb-8'>
        <KTCard>
          <ChampionshipTable winnersData={winnersData} totalWinners={totalWinners} />
        </KTCard>
      </div>
      <div className='mb-8'>
        <KTCard>
          <SignUpTable signupsData={signupsData} totalSignUps={totalSignUps} />
        </KTCard>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
