import styles from './championshipRow.module.scss'
import earth from '../../assets/Earth.png'
import defaultPic from '../../assets/DefAvatar.png'
import rp from '../../assets/RP.png'
import coin from '../../assets/Coin.png'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ENVIRONMENT} from '../../../../api/client'
import {FileAddressGenerator} from '../../../../utilitites/fileAddressGenerator'

type Props = {
  position: number
  nickname: string
  userId: string
  points: string
  amount: string
  country: string
  tournamentName: string
  img: string
  isSpecial?: boolean
}
const ChampionshipRow = ({
  position,
  nickname,
  userId,
  amount,
  points,
  country,
  tournamentName,
  img,
  isSpecial,
}: Props) => {
  let style = {
    backgroundColor: isSpecial ? 'orange' : '',
  }
  const defaultResourse = ENVIRONMENT === 'prod' ? 'api' : 'test-api'

  const resp = FileAddressGenerator(defaultResourse, img)

  const handleDownload = () => {
    const link = document.createElement('a')

    // Fetch the file as a Blob and trigger download
    fetch(resp)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        link.href = url
        const filename = resp.split('/').pop() || 'downloaded_file'
        link.setAttribute('download', filename) // Force a download with filename
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click() // Trigger the download
        document.body.removeChild(link) // Clean up the DOM
        window.URL.revokeObjectURL(url) // Free up the object URL
      })
      .catch((error) => console.error('Error downloading the file:', error))
  }

  return (
    <div className={styles.userRowSection} style={style}>
      <span className={styles.ranking}>
        <b># {position}</b>
      </span>

      <span className={styles.flag}>
        {country ? (
          <img className={styles.flag} alt='code2' src={`/Flags/${country}.png`} />
        ) : (
          <img className={styles.flag} alt='' src={earth} />
        )}
      </span>

      <span className={styles.img}>
        <img className={styles.img} alt='prof pic' src={img ? resp : defaultPic}></img>
      </span>
      <span className={styles.imgDownload} onClick={handleDownload}>
        <KTIcon iconName='bi bi-download' className='fs-2 text-black ml-3' />
      </span>
      <span className={styles.name}>
        {' '}
        <b>{nickname}</b>
      </span>
      <span className={styles.userId}>
        <b>{`[${userId}]`}</b>
      </span>
      <span className={styles.amount}>
        <img className={styles.img} alt='' src={rp} />
        <b>{amount}</b>
      </span>
      <span className={styles.score}>
        <img className={styles.flag} alt='' src={coin} />
        <b>{points}</b>
      </span>
    </div>
  )
}

export default ChampionshipRow
