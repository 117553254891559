import axios from 'axios'
import {ParticipationsPayload} from '../models/response/ParticipationsPayload'
import coreClient from '../coreClient'

export function getParticipants(pageNumber?: number, pageSize?: number, start?: any, end?: any) {
  let path = `/Participations/Participants`
  if (pageNumber !== null || pageSize !== null || start !== null || end !== null) {
    path += `?`
  }
  if (pageNumber) {
    path += `PageNumber=${pageNumber}&`
  }
  if (pageSize) {
    path += `PageSize=${pageSize}&`
  }
  if (start) {
    path += `StartDateStart=${start}&`
  }
  if (end) {
    path += `StartDateEnd=${end}`
  }
  return coreClient.get<ParticipationsPayload[]>(path)
}

export function getRewardedVideosPerUser(
  scroreMultiplier: number,
  pageNumber?: number,
  pageSize?: number,
  start?: any,
  end?: any
) {
  let path = `/Participations/Participants`
  if (
    pageNumber !== null ||
    pageSize !== null ||
    start !== null ||
    end !== null ||
    scroreMultiplier !== null
  ) {
    path += `?`
  }
  if (scroreMultiplier) {
    path += `ScoreMuliplier=${scroreMultiplier}&`
  }
  if (pageNumber) {
    path += `PageNumber=${pageNumber}&`
  }
  if (pageSize) {
    path += `PageSize=${pageSize}&`
  }
  if (start) {
    path += `StartDateStart=${start}&`
  }
  if (end) {
    path += `StartDateEnd=${end}`
  }
  return coreClient.get<ParticipationsPayload[]>(path)
}
 
        
export function get(
  userId?: string,
  orderBy?: string,
  scoreStart?: number | null,
  highestOnly?: boolean | null,
  coinStart?: number | null,
  tournamentId?: number | string | null,
  pageNumber = 1,
  pageSize = 99999
) {
  var path = `/Participations?PageNumber=${pageNumber}&PageSize=${pageSize}&`

  if (userId !== null && userId !== undefined ) {
    path += `UserId=${userId}&`
  }

  if (orderBy !== null && orderBy !== undefined) {
    path += `OrderBy=${orderBy}&`
  }

  if (scoreStart !== null && scoreStart !== undefined) {
    path += `ScoreStart=${scoreStart}&`
  }
  if (highestOnly !== null && highestOnly !== undefined) {
    path += `HighestOnly=${highestOnly}&`
  }
  if (coinStart !== null && coinStart !== undefined) {
    path += `CoinStart=${coinStart}&`
  }
  if (tournamentId !== null && tournamentId !== undefined) {
    path += `TournamentIds=${tournamentId}&`
  }

  return coreClient.get<ParticipationsPayload[]>(path)
}


export function getAllParticipations(query: string) {
  return coreClient.get(`/Participations?${query}`, {
    headers: {accept: 'application/json'},
  })
  // .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}