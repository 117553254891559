import coreClient from "../coreClient";

export function getTransactionAdditions(query: string) {
    return coreClient.get(`/TransactionAdditions?${query}&CurrencyId=2&HasParticipation=false`, {
      headers: {accept: 'application/json'},
    })
    // .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  export function getCoinExecutions(query: string) {
    return coreClient.get(`/TransactionExecutions?${query}`, {
      headers: {accept: 'application/json'},
    })
    // .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }