import axios, {AxiosResponse} from 'axios'
import {TournamentsPayload} from '../models/response/TournamentsPayload'
import {CreateTournamentPayload, TournamentBatch} from '../models/payload/CreateTournamentPayload'
import coreClient from '../coreClient'
import { ID } from '../../../_metronic/helpers'

let axiosConfig = {
  headers: {
    accept: 'application/json',
  },
}

export function getByIdtournaments(id: number) {
  return coreClient.get<TournamentsPayload>(`/Tournaments/${id}`, {
    headers: {accept: 'application/json'},
  })
}

export function getTournaments(query: string)  {
  return coreClient.get(`/Tournaments?${query}`, {
    headers: {accept: 'application/json'},
  })
}
export function getFinishedTournaments() {
  return coreClient.get<TournamentsPayload[]>(
    `/Tournaments/1/Finished`,
    {
      headers: {accept: 'application/json'},
    }
  )
}

export function pushnotifications() {
  return coreClient.get<TournamentsPayload[]>(
    `/Tournaments/id/PushNotifications?Message=as`,
    {
      headers: {accept: 'application/json'},
    }
  )
}

export function posttournaments(payload: CreateTournamentPayload): Promise<AxiosResponse<any>> {
  return coreClient.post<any>(
    `/Tournaments/Data`,
    payload,
    axiosConfig
  )
}

export const deleteTournament = (userId: ID): Promise<void> => {
  return coreClient.delete(`/Tournaments/${userId}`).then(() => {})
}

export const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) =>
    coreClient.delete(`/Tournaments/${id}`)
  )
  return axios.all(requests).then(() => {})
}

// export function deleteTournamentCountry(
//   id: number,
//   countryID: number
// ): Promise<AxiosResponse<any>> {
//   return coreClient.delete<any>(
//     `/Tournaments/${id}/Countries/${countryID}`
//   )
// }

// export function deleteTournamentImage(id: number, imageID: number): Promise<AxiosResponse<any>> {
//   return coreClient.delete<any>(
//     `/Tournaments/${id}/Images/${imageID}`
//   )
// }

// export function updateTournament(id: number, payload: any): Promise<AxiosResponse<any>> {
//   return coreClient.put<any>(
//     `/Tournaments/${id}`,
//     payload,
//     axiosConfig
//   )
// }

export function updateTournamentsBatch(payload: TournamentBatch): Promise<AxiosResponse<any>> {
  return coreClient.put<TournamentBatch>(
    `/Tournaments/Batch`,
    payload
  )
}
