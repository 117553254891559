import axios from 'axios'
import client from '../client'
import { GoogleLoginCreds, LoginCreds, NewUserModel } from '../../modules/auth/core/_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
// export function login(email: string, password: string) {
//   return axios.post<AuthModel>(LOGIN_URL, {
//     email,
//     password,
//   })
// }
export function login(
  payload?: LoginCreds
) {
  return client.post(`/users/authorize`, payload, {
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  })
}

export function googleAuth(
  payload?: GoogleLoginCreds,
) {
  return client.post(`/Users/Authorize/Google`, payload, {
      headers: { 'Content-Type': 'application/json' },
    
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

// export function getUserByToken(token: string) {
//   return client.get<NewUserModel>(`/Users`, {
//     headers: {'Authorization': `Bearer ${token}`},
//   })
// }

export function getUserByToken(token: string) {
  return client.post<any>(`/Users/Info`, {}, {
    headers: {'Authorization': `Bearer ${token}`},
  })
}